/**
 * Converts a file accessible via a URL into a File object.
 *
 * @param {string} url - The URL from which the file will be downloaded. It should be a valid URL pointing to an accessible file.
 * @param {string} fileName - The name that will be assigned to the downloaded file. This name will be used when creating the File object.
 * @returns {Promise<File | undefined>} A promise that resolves to a File object if the URL is accessible and the file is downloaded successfully. If an error occurs, the promise resolves to undefined.
 *
 * @description
 * This function performs a GET request to the provided URL to check if the file is accessible. If the response is successful,
 * it then makes a second request to download the file as a blob and converts it into a File object. This File object can be used
 * in web applications, such as submitting in forms or sending in requests.
 *
 * If the URL is not accessible or the file cannot be retrieved, an error is thrown or `undefined` is returned.
 */
export const convertUrlFile = async (url: string, fileName: string): Promise<File | undefined> => {
    try {

        const response = await fetch(url, { method: "GET" });

        if (!response.ok) {

            throw new Error(`El archivo no existe o no es accesible: ${response.statusText}`);

        }
        const fileResponse = await fetch(url);

        const blob = await fileResponse.blob();

        return new File([blob], fileName, { type: blob.type });

    } catch {

        return undefined;

    }
};