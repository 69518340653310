/**
 * Converts a Base64 string into a `File` object.
 *
 * @param base64 - The Base64 string representing the file content.
 *                 It must be in the format `data:[mime-type];base64,...`.
 * @param fileName - The name to assign to the resulting file.
 * @returns A `File` object if the conversion is successful; otherwise, returns `null`.
 */

export const convertBase64File = (base64: string, fileName: string): File | undefined => {
    try {
        const [metadata, base64Data] = base64.split(",");
        if (!metadata || !base64Data) {
            throw new Error("Invalid Base64 format");
        }

        const mime = metadata.match(/:(.*?);/)?.[1] || "";
        if (!mime) {
            throw new Error("Could not determine the MIME type");
        }

        const byteString = atob(base64Data);

        const byteNumbers = new Uint8Array(byteString.length);

        for (let i = 0; i < byteString.length; i++) {
            byteNumbers[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([byteNumbers], { type: mime });

        return new File([blob], fileName, { type: mime });
    } catch {
        return undefined;
    }
};