import { IconChevronRight, IconLock, IconLockOpen, IconSearch } from "@tabler/icons-react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
//
import { Avatar, Badge, RadioButton, Select, Table, TablePagination, TextField } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { Appointment, AttentionAppointmentResponse, AttentionCounter, AttentionCounterParams } from "@/models/appointment";
import { getAttentionAppointments, getAttentionAppointmentsCounters, getDoctors, getSites } from "./consultation.actions";
//
import { Doctor, Site } from "@/models";
import { useLocation } from "react-router-dom";
import AppointmentDetail from "./AppointmentDetail/AppointmentDetail";
import "./Consultation.scss";
// import routes from "@/constants/routes";
import { formatteDocument } from "@/utils";

type FilterStates = "pending" | "attended" | "all" | "reprocessing";

export default function Consultation() {
    const userId = useAppSelector((state) => state.auth.user_data.id);
    const workspace = useAppSelector((state) => state.workspace);
    const dispatch = useAppDispatch();
    const location = useLocation();
    dayjs.extend(relativeTime);
    dayjs.extend(isToday);

    const [appointmentDetailConfig, setAppointmentDetailConfig] = useState<{ isOpen: boolean; data: Appointment | null }>({
        isOpen: false,
        data: null,
    });

    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [trigger, setTrigger] = useState(0);
    const [counters, setCounters] = useState<AttentionCounter>({
        ALL: 0,
        ATTENDED: 0,
        PENDING: 0,
        REPROCESSING: 0,
    });
    const [appointmentsResult, setAppointmentsResult] = useState<AttentionAppointmentResponse>();
    const [filters, setFilters] = useState<AttentionCounterParams>({
        userId: userId as number,
        eaccount: workspace.id,
        roleUsed: workspace.profile?.prefijo as string,
        pending: 1,
        appDate: dayjs().format("YYYY-MM-DD"),
        status: "",
        page: 1,
        perpage: 10,
        module: "",
    });
    const [currentFilter, setCurrentFilter] = useState<FilterStates>("pending");

    useEffect(() => {
        async function fetchData() {
            if (workspace.profile?.prefijo.toLowerCase() === "nurse") {
                const data = await dispatch(getDoctors(workspace.id));
                setDoctors(data as Doctor[]);
            } else {
                const data = await dispatch(
                    getSites({
                        eaccount: workspace.id,
                        status: "enabled",
                    })
                );
                setSites(data as Site[]);
            }
        }
        fetchData();
    }, [dispatch, workspace.id, workspace.profile, location.pathname]);

    useEffect(() => {
        (async function () {
            if (filters) {
                const data = await dispatch(
                    getAttentionAppointmentsCounters({
                        ...filters,
                        module: location.pathname.includes("DiagnosticAids") ? "diagnostic_aids" : "attention",
                    })
                );
                setCounters(data as AttentionCounter);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trigger, location.pathname]);

    useEffect(() => {
        (async function () {
            if (filters) {
                const data = await dispatch(
                    getAttentionAppointments({
                        ...filters,
                        module: location.pathname.includes("DiagnosticAids") ? "diagnostic_aids" : "attention",
                    })
                );
                setAppointmentsResult(data as AttentionAppointmentResponse);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, trigger, location.pathname]);

    const handleOpenDrawer = (appointment: Appointment): void => {
        setAppointmentDetailConfig({ isOpen: true, data: appointment });
    };
    const handleCloseDrawer = (): void =>
        setAppointmentDetailConfig({
            isOpen: false,
            data: null,
        });

    const handleNextPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handlePrevPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };

    const handleMaxPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };
    const handleMinPage = (value: number): void => {
        setFilters({ ...filters, page: value });
        setTrigger(trigger + 1);
    };

    const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, [target.name]: target.value });
    };

    const handleRadioChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        setCurrentFilter(target.value as FilterStates);
        const filter = { ...filters };
        delete filter.all;
        delete filter.attended;
        delete filter.pending;
        delete filter.reprocessing;

        setFilters({ ...filter, [target.value]: 1, page: 1 });
        setTrigger(trigger + 1);
    };

    const handleSelectChange = (value: number, name: string) => {
        if (name === "doctor") {
            setFilters({ ...filters, doctor: String(value) });
        }
        if (name === "site") {
            setFilters({ ...filters, siteId: value });
        }
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLDataElement>) => {
        setFilters({ ...filters, appDate: event.target.value });
    };

    const renderRow = (appointment: Appointment) => {
        const currentTime = dayjs();
        const startAppointment = dayjs(appointment.startAppointment);
        let delayTime: string | null = null;

        if (appointment.status === "admitted" && currentTime.isAfter(startAppointment)) {
            delayTime = startAppointment.fromNow(true);
        }

        const validStatus: string[] = ["admitted", "calling", "preconsulting", "consulting", "attended"];

        return (
            <tr
                key={appointment.app_id}
                style={{
                    backgroundColor: startAppointment.isToday()
                        ? appointment.reqDilate || appointment.alwaysDilate
                            ? "#fcfae6"
                            : "#FFF"
                        : startAppointment.isAfter(currentTime, "day")
                            ? "#F9F9FA"
                            : "#FFF",
                }}
            >
                <td width={200}>
                    <div className="d-flex align-items-center justify-content-start">
                        <Avatar
                            src={
                                appointment.photoUrl !== null && appointment.photoUrl !== ""
                                    ? appointment.photoUrl
                                    : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                            }
                        />{" "}
                        <div
                            className="d-flex flex-column nowrap"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                                appointment.firstTime
                                    ? "Primera vez"
                                    : appointment.reqDilate
                                        ? appointment.alwaysDilate
                                            ? "Siempre"
                                            : appointment.timeConfigDilTag
                                        : ""
                            }
                        >
                            <span className="fw-bold">{appointment.patientName}</span>
                            <span className="text-muted">{formatteDocument({ value: appointment.patientIdentification }).format}</span>
                        </div>
                        {appointment.reqDilate || appointment.firstTime || appointment.alwaysDilate ? <Tooltip id="my-tooltip" /> : ""}
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-column nowrap">
                        <span className="fw-bold">{startAppointment.format("hh:mm a")}</span>
                        <span className="text-muted">{delayTime && delayTime + " en espera"}</span>
                    </div>
                </td>
                <td>
                    <span className="fw-bold">{dayjs(appointment.admittedDate).format("hh:mm a")}</span>
                </td>
                <td>
                    <span className="fw-bold">{appointment.companyName}</span>
                </td>
                <td>
                    <span className="fw-bold">{appointment.companyType}</span>
                </td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 150 }}
                    title={appointment.contractName}
                >
                    <span className="fw-bold">{appointment.contractName}</span>
                </td>
                <td
                    className="overflow-hidden text-ellipsis"
                    style={{ maxWidth: 100 }}
                    title={appointment.serviceName}
                >
                    <span className="fw-bold">{appointment.serviceName}</span>
                </td>
                <td>
                    <span className="fw-bold">{appointment.site}</span>
                </td>
                <td>
                    <span className="fw-bold">{appointment.consultingRoom}</span>
                </td>
                <td>
                    <Badge
                        style={{
                            backgroundColor: appointment.statusBgColor || "#E8E8EA",
                            color: appointment.statusFontColor || "#6E6F7C",
                        }}
                    >
                        {String(appointment.status) === "consulting" && location.pathname.includes("DiagnosticAids")
                            ? "Ayudas DX"
                            : appointment.statusName}
                    </Badge>
                </td>
                <td>
                    {startAppointment.isToday() ? (
                        validStatus.includes(appointment.status) && appointment.modalityAppoinment === "on_site" ? (
                            <IconLockOpen className="text-success" />
                        ) : (
                            <IconLock />
                        )
                    ) : (
                        <IconLock />
                    )}
                </td>
                <td
                    className="text-primary pointer"
                    onClick={() => handleOpenDrawer(appointment)}
                >
                    <IconChevronRight />
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Paciente</th>
                        <th>Hora de cita</th>
                        <th>Hora registro</th>
                        <th>Aseguradora</th>
                        <th>Tipo</th>
                        <th>Contrato</th>
                        <th>Servicio</th>
                        <th>Sede</th>
                        <th>Consultorio</th>
                        <th>Estado</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentsResult?.results?.length ? (
                        appointmentsResult.results.map((appointment) => renderRow(appointment))
                    ) : (
                        <tr>
                            <td
                                colSpan={12}
                                align="center"
                                style={{ padding: "15px 0" }}
                            >
                                No hay citas disponibles para la fecha seleccionada.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    };

    const renderFilters = () => {
        const role = workspace.profile?.prefijo;
        const formattedDoctors = doctors.map((doc) => ({
            value: doc.id,
            label: `${doc.first_name || ""} ${doc.second_name || ""} ${doc.first_surname || ""} ${doc.second_surname || ""}`,
        }));
        const formattedSites = sites.map((site) => ({
            value: site.siteId,
            label: site.site,
        }));

        return (
            <div className="d-flex align-items-end mb-5">
                <div
                    className="me-3"
                    style={{ width: "12%" }}
                >
                    <label
                        htmlFor="status"
                        className="text-label"
                    >
                        {role?.toLowerCase() === "nurse" ? "Médico" : "Sede"}
                    </label>
                    {role?.toLowerCase() === "nurse" ? (
                        <Select
                            isSearchable
                            placeholder="Seleccionar..."
                            emptyOptionsLabel="No hay opciones disponibles."
                            options={formattedDoctors}
                            name="doctor"
                            onChange={({ option, name }) => handleSelectChange(option.value, name)}
                        />
                    ) : (
                        <Select
                            isSearchable
                            placeholder="Seleccionar..."
                            emptyOptionsLabel="No hay opciones disponibles."
                            options={formattedSites}
                            name="site"
                            onChange={({ option, name }) => handleSelectChange(option.value, name)}
                        />
                    )}
                </div>
                <div className="me-3">
                    <label className="text-label">Fecha</label>
                    <TextField
                        type="date"
                        value={filters.appDate}
                        onChange={handleDateChange}
                        name="appDate"
                        placeholder="DD/MM/AAAA"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setTrigger(trigger + 1);
                            }
                        }}
                        style={{ color: "#999" }}
                    />
                </div>
                <TextField
                    placeholder="Escribe aquí para buscar"
                    variant="filled"
                    endIcon={
                        <IconSearch
                            className="pointer"
                            onClick={() => setTrigger(trigger + 1)}
                        />
                    }
                    className="flex-grow-1 me-4"
                    name="search"
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setTrigger(trigger + 1);
                        }
                    }}
                />
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${currentFilter === "pending" ? "bg-primary bg-opacity-10" : ""}`}
                    label={`Pendientes (${counters?.PENDING})`}
                    value="pending"
                    id="pending"
                    name="filter"
                    checked={currentFilter === "pending"}
                    onChange={handleRadioChange}
                />
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${currentFilter === "attended" ? "bg-primary bg-opacity-10" : ""}`}
                    label={`Atendidas (${counters?.ATTENDED})`}
                    value="attended"
                    id="attended"
                    name="filter"
                    checked={currentFilter === "attended"}
                    onChange={handleRadioChange}
                />
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${currentFilter === "reprocessing" ? "bg-primary bg-opacity-10" : ""}`}
                    label={`Reproceso (${counters?.REPROCESSING})`}
                    value="reprocessing"
                    id="reprocessing"
                    name="filter"
                    checked={currentFilter === "reprocessing"}
                    onChange={handleRadioChange}
                />
                <RadioButton
                    className={`py-2 px-3 fs-5 rounded ${currentFilter === "all" ? "bg-primary bg-opacity-10" : ""}`}
                    label={`Todas (${counters?.ALL})`}
                    value="all"
                    id="all"
                    name="filter"
                    checked={currentFilter === "all"}
                    onChange={handleRadioChange}
                />
            </div>
        );
    };

    const render = () => {
        return (
            <section className="consultation d-flex flex-column w-100 h-100 overflow-auto">
                <h1 className="text-secondary fw-bold mb-4 display-5">Atención de pacientes</h1>
                {renderFilters()}

                <div className="d-flex flex-column flex-grow-1">
                    <div className="table-responsive">{renderTable()}</div>
                    <TablePagination
                        totalPages={appointmentsResult?.rowTotal as number}
                        perPage={10}
                        currentPage={filters.page as number}
                        totalCount={appointmentsResult?.results?.length as number}
                        onNextPage={(value) => handleNextPage(value as number)}
                        onPrevPage={(value) => handlePrevPage(value as number)}
                        onMaxPage={(value) => handleMaxPage(value as number)}
                        onMinPage={(value) => handleMinPage(value as number)}
                    />
                </div>
                <AppointmentDetail
                    isOpen={appointmentDetailConfig.isOpen}
                    onClose={handleCloseDrawer}
                    drawer="right"
                    width={500}
                    data={appointmentDetailConfig.data as Appointment}
                    onSuccess={async () => {
                        const data = await dispatch(
                            getAttentionAppointments({
                                ...filters,
                                module: location.pathname.includes("DiagnosticAids") ? "diagnostic_aids" : "attention",
                            })
                        );
                        setAppointmentsResult(data as AttentionAppointmentResponse);
                    }}
                />
            </section>
        );
    };

    return render();
}
