import { Avatar, Dropdown, DropdownItem, DropdownMenu } from "@/components";
import { useAppDispatch } from "@/hooks";
import { AttentionHistoryList } from "@/models/history";
import { HeaderSurgery } from "@/models/surgery/patientAttention/headerSurgeryAttention";
import { formatteDocument } from "@/utils";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getAttentionHistoryList } from "../../../history.actions";

export default function SurgeryHeader({
    patientHeader,
    title,
    setAppIdState,
    prefix = "surgery",
}: {
    patientHeader: HeaderSurgery;
    title?: string;
    setAppIdState?: (item: number) => void;
    prefix?: string;
}) {
    const dispatch = useAppDispatch();
    const [attentionHistoryList, setAttentionHistoryList] = useState<AttentionHistoryList[]>([]);
    const [selectedDetail, setSelectedDetail] = useState<AttentionHistoryList>({});

    useEffect(() => {
        async function fetch() {
            const response = await dispatch(
                getAttentionHistoryList({
                    prefix,
                    patient_id: patientHeader.patient.id,
                })
            );
            if (response) {
                const selected = response.find((item) => item.appId === patientHeader?.appId);
                setAttentionHistoryList(response);
                if (selected) setSelectedDetail(selected);
            }
        }
        fetch();
    }, [dispatch]);

    const renderDropdown = () => {
        return attentionHistoryList.length ? (
            <Dropdown className="dropdown text-muted">
                <div
                    className="d-flex align-items-center pointer text-muted"
                    data-bs-toggle="dropdown"
                >
                    <div className="d-flex flex-column">
                        <span className="fw-bold">{selectedDetail.createdAt}</span>
                        <span>{selectedDetail.medName}</span>
                    </div>
                    <IconChevronDown />
                </div>
                <DropdownMenu
                    className="overflow-auto"
                    style={{ maxHeight: 300 }}
                >
                    {attentionHistoryList.map((item) => (
                        <DropdownItem
                            className={`${item.appId === selectedDetail.appId ? "active text-white" : "text-muted"}`}
                            onClick={() => {
                                setSelectedDetail(item);
                                if (setAppIdState && item.appId) {
                                    setAppIdState(item?.appId);
                                }
                                // onChangeDetail?.(item.mcfId as number);
                                // setIsDocumentReady(false);
                            }}
                        >
                            <div className="d-flex flex-column">
                                <span className="fw-bold">{item.createdAt}</span>
                                <span>{item.medName}</span>
                            </div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        ) : null;
    };

    const renderComponent = () => {
        return (
            <div className="surgery-header">
                <Avatar
                    src={
                        patientHeader.patient.photo
                            ? patientHeader.patient.photo
                            : "https://www.clevelanddentalhc.com/wp-content/uploads/2018/03/sample-avatar.jpg"
                    }
                />
                <h3 className="m-0 fw-bold text-secondary ms-3">{title ? title : "Cirugía"} </h3>
                <div className="d-flex align-items-center ms-3">
                    <div className="ms-3">
                        <small className="text-muted">Paciente</small>
                        <div className="fw-bold text-secondary">{patientHeader.patient.fullName}</div>
                    </div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Identificación</small>
                    <div className="fw-bold text-secondary">
                        {patientHeader.patient.documentType} {formatteDocument({ value: patientHeader.patient.document }).format}
                    </div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">EPS</small>
                    <div className="fw-bold text-secondary">{patientHeader.cclientName}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Contrato</small>
                    <div className="fw-bold text-secondary">{patientHeader.contractName}</div>
                </div>
                <div className="ms-4">
                    <small className="text-muted">Edad</small>
                    <div className="fw-bold text-secondary">{patientHeader.patient.age} años</div>
                </div>
                <div className="ms-4 d-flex justify-content-end">{renderDropdown()}</div>
            </div>
        );
    };

    return renderComponent();
}
